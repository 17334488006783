.wk{
    width: 30%;
    height: 40vh;
    margin: 20px 10px;
    border: 2px solid rgb(243, 242,242);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}

.wk-browser{
    height: 30px;
    background-color: rgb(243, 242,242);
    display: flex;
    align-items: center;
}

.wk-circle{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 3px;
    background-color: white;

}

.wk-img{
    width: 100%;
}


@media screen and  (max-width:480px){
    .wk{
        width: 100%;
        height: 30vh;
    }


   img{
       width: 100%;
   }
  
} 
.w{
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.w-bg{
    width: 20px;
    height: 100%;
    background-color: rgb(58, 188, 205);
    position: absolute;
}

.w-texts{
    width: 65%;
}

.w-title{
    font-size: 50px;
    font-weight: 600;
}

.w-desc{
   margin: 20px 0px;
   font-size: 25px;
}

.w-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


@media screen and  (max-width:480px){
    .w{
     padding: 10px;   
    }

    .w-texts{
        width: 100%;
    }

    .w-desc{
        font-size: 15px;
    }
    .w-title{
        font-size: 30px;
  
    }
} 
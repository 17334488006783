.c{
    height: 100vh;
    position: relative;
}

.c-bg{
    width: 20px;
    height: 100%;
    background-color: green;
    position: absolute;
}

.c-wrapper{
    padding: 50px;
    display: flex;
}

.c-right{
    flex: 1;
}

.c-left{
    flex: 1;
}

.c-title{
    font-size: 60px;
    width: 80%;
}

.c-info-item{
    display: flex;
    align-items: center;
    margin: 50px 0;
    font-weight: 300;
    width: 70%;
}

.c-info-item a{
    color: black;
    text-decoration: none;
}

.c-icon{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

form{
    margin-top: 20px;
}

input{
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0;
    font-size: 14px;
    padding-left: 10px;
}

textarea{
    width: 100%;
}

button{
    border: none;
    padding: 15px;
    border-radius: 10px;
    background-color: #59b256;
    color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;

}

.form-consent{
    font-size: 10px;
}

@media screen and  (max-width:480px){
    .c-wrapper{
     flex-direction: column;   
    }
    .c-title{
        font-size: 30px;
  
    }

}
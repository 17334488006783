.a{
    height: 100vh;
    display: flex;
    align-items: center;
}

.a-left{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.a-right{
    flex: 1;
}

.a-card.bg{
    position: absolute;
    top: 130px;
    left: 160px;
    background-color: rgba(141, 135, 135, 0.531);
}

.a-card{
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.a-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.a-sub{
    margin: 20px 0px;
}

.a-desc{
    font-weight: 300;
    font-size: 20px;
}

@media screen and (max-width:480px){
    .a{
        flex-direction:  column;
        text-align: center;
        margin-top: 50px;
    }

    .a-left{
        width: 100%;

    }
    .a-card{
        height: 30vh;
    }
    .a-card.bg{
        display: none;

    }
    .a-desc{

        font-size: 15px;
    }
    .a-right{
        padding: 20px;
    }
    .a-title{
        font-size: 30px;
  
    }
}